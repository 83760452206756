<!--  -->
<template>
  <div>
    <blockTitle title="租户列表"></blockTitle>
    <el-card shadow="never">
      <!--      <div class="operator-box flex flex-row justify-between mb-3">-->
      <!--        <div class="create-client">-->
      <!--          <el-button size="small" type="primary">添加租户</el-button>-->
      <!--        </div>-->
      <!--        <div class="search-box w-64">-->
      <!--          <el-input size="mini" placeholder="输入客户名称进行搜索">-->
      <!--            <el-button slot="append" icon="el-icon-search"></el-button>-->
      <!--          </el-input>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--租户列表-->
      <div
          class="grid gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4"
      >
        <div
            class="grid-content client-item relative"
            v-for="(item, index) in clientList"
            @mouseenter="deviceMouseenter(item)"
            @mouseleave="deviceMouseleave(item)"
            :key="index"
        >
          <div
              class="company-name overflow-ellipsis overflow-hidden"
              @click="toDetail(item)"
          >
            {{ item.name }}
          </div>
          <div class="dtm-status r-f">
            <div class="create-dtm r-f-i">
              <span class="t-o-h"> 创建时间：{{ item.createDtm }} </span>
            </div>
            <div class="status-box r-f-i">
              <div></div>
            </div>
          </div>

          <div class="progress-box" @click="toDetail(item)">
            <!--            <div class="progress-item r-f">-->
            <!--              <el-progress class="progress r-f-i" :width="36" :stroke-width="2" type="circle"-->
            <!--                           :percentage="(item.totalContainerSize > 0) ? parseInt((item.useContainerSize / item.totalContainerSize ) * 100 ): 0"></el-progress>-->
            <!--              <div class="info r-f-i">-->
            <!--                <span class="label">存储</span>-->
            <!--                <span class="used">{{ item.useContainerSize }}GB</span>-->
            <!--                <span class="total">/{{ item.totalContainerSize }}GB</span>-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="progress-item r-f">
              <el-progress
                  class="progress r-f-i"
                  :width="36"
                  :stroke-width="2"
                  type="circle"
                  :percentage="
                  item.totalDeviceQuantity > 0
                    ? parseInt(
                        item.useDeviceQuantity / item.totalDeviceQuantity
                      ) * 100
                    : 0
                "
              ></el-progress>
              <div class="info r-f-i">
                <span class="label">在线</span>
                <span class="used">{{ item.useDeviceQuantity }}</span>
                <span class="total">/{{ item.totalDeviceQuantity }}台</span>
              </div>
            </div>
            <div class="progress-item r-f" v-if="item.licenseBillProject">
              <el-progress
                  class="progress r-f-i"
                  :width="36"
                  :stroke-width="2"
                  type="circle"
                  :percentage="
                  item.licenseBillProject.quantity > 0
                    ? parseInt(
                        (item.licenseBillProject.assignNum /
                          item.licenseBillProject.quantity) *
                          100
                      )
                    : 0
                "
              ></el-progress>
              <div class="info r-f-i">
                <span class="label">授权</span>
                <span class="used">{{
                    item.licenseBillProject.assignNum
                  }}</span>
                <span class="total"
                >/{{ item.licenseBillProject.quantity }}台</span
                >
              </div>
            </div>
          </div>
          <div
              class="delete-device rounded-full absolute -right-3 -top-4"
              v-if="mouseCurDevice.id === item.id"
              @click="deleteClient(item)"
          >
            <i class="el-icon-error text-red-500 text-2xl"></i>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less">
.client-item {
  display: inline-block;
  padding: 15px;
  user-select: none;
  cursor: pointer;
  margin: 5px;
  //margin: 5px 5px 20px 5px;
  //overflow: hidden;
  transition: 0.2s;
  background-color: #f5f7fa;
  background-size: 50px 50px;
  //width: 350px;
  //height: 300px;
  border: 1px solid #d5d9df;
  border-radius: 8px;

  .company-name {
    font-size: 18px;
    font-weight: 600;
  }

  .dtm-status {
    border-bottom: 1px dashed #919191;
    height: 40px;
    justify-content: space-between;

    .create-dtm {
      font-size: 12px;
      border-radius: 0;
      color: #888;
      line-height: 42px;
      height: 30px;
      min-width: 100px;

      span {
        padding-bottom: 2px;
        border-bottom: 1px dashed #919191;
      }
    }

    .status-box {
      height: 40px;
      line-height: 40px;
      justify-content: flex-end;

      div {
        width: 10px;
        height: 10px;
        background: green;
        float: left;
        border-radius: 450px;
        margin: 14px 4px 0 0;
        text-align: right;
      }

      &:after {
        content: '启用';
        font-size: 12px;
      }

      @media screen and (max-width: 1400px) {
        &:after {
          content: '';
          font-size: 12px;
        }
      }
    }
  }

  .progress-box {
    padding: 4px 0 0 0;

    .progress-item {
      margin-top: 8px;
      float: left;
      //width: 50%;
      width: 169px;
      font-size: 12px;

      .progress {
        float: left;
      }

      .info {
        float: left;
        padding: 4px 0 0 4px;
        align-items: baseline;

        .label {
          padding-right: 4px;
        }

        .used {
          font-size: 16px;
          font-weight: 800;
        }

        .total {
          font-weight: 500;
        }
      }
    }
  }
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
  //margin-bottom: 20px;
}
</style>
<script type="text/ecmascript-6">

export default {
  data () {
    return {
      clientList: [],
      mouseCurDevice: {}
    }
  },
  components: {},
  methods: {
    getData () {
      this.$api.page('customerInfo', {
        size: 999
      }).then(res => {
        this.clientList = res.data.content
      })
    },
    toDetail (item) {
      this.$router.push({
        name: 'clientDetails',
        query: {
          id: item.id
        }
      })
    },
    deviceMouseenter (item) {
      this.mouseCurDevice = item
    },
    deviceMouseleave () {
      this.mouseCurDevice = {}
    },
    deleteClient (item) {
      this.$api.customerInfo.delete(item).then(() => {
        this.getData()
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
