var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('blockTitle',{attrs:{"title":"租户列表"}}),_c('el-card',{attrs:{"shadow":"never"}},[_c('div',{staticClass:"grid gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4"},_vm._l((_vm.clientList),function(item,index){return _c('div',{key:index,staticClass:"grid-content client-item relative",on:{"mouseenter":function($event){return _vm.deviceMouseenter(item)},"mouseleave":function($event){return _vm.deviceMouseleave(item)}}},[_c('div',{staticClass:"company-name overflow-ellipsis overflow-hidden",on:{"click":function($event){return _vm.toDetail(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"dtm-status r-f"},[_c('div',{staticClass:"create-dtm r-f-i"},[_c('span',{staticClass:"t-o-h"},[_vm._v(" 创建时间："+_vm._s(item.createDtm)+" ")])]),_c('div',{staticClass:"status-box r-f-i"},[_c('div')])]),_c('div',{staticClass:"progress-box",on:{"click":function($event){return _vm.toDetail(item)}}},[_c('div',{staticClass:"progress-item r-f"},[_c('el-progress',{staticClass:"progress r-f-i",attrs:{"width":36,"stroke-width":2,"type":"circle","percentage":item.totalDeviceQuantity > 0
                  ? parseInt(
                      item.useDeviceQuantity / item.totalDeviceQuantity
                    ) * 100
                  : 0}}),_c('div',{staticClass:"info r-f-i"},[_c('span',{staticClass:"label"},[_vm._v("在线")]),_c('span',{staticClass:"used"},[_vm._v(_vm._s(item.useDeviceQuantity))]),_c('span',{staticClass:"total"},[_vm._v("/"+_vm._s(item.totalDeviceQuantity)+"台")])])],1),(item.licenseBillProject)?_c('div',{staticClass:"progress-item r-f"},[_c('el-progress',{staticClass:"progress r-f-i",attrs:{"width":36,"stroke-width":2,"type":"circle","percentage":item.licenseBillProject.quantity > 0
                  ? parseInt(
                      (item.licenseBillProject.assignNum /
                        item.licenseBillProject.quantity) *
                        100
                    )
                  : 0}}),_c('div',{staticClass:"info r-f-i"},[_c('span',{staticClass:"label"},[_vm._v("授权")]),_c('span',{staticClass:"used"},[_vm._v(_vm._s(item.licenseBillProject.assignNum))]),_c('span',{staticClass:"total"},[_vm._v("/"+_vm._s(item.licenseBillProject.quantity)+"台")])])],1):_vm._e()]),(_vm.mouseCurDevice.id === item.id)?_c('div',{staticClass:"delete-device rounded-full absolute -right-3 -top-4",on:{"click":function($event){return _vm.deleteClient(item)}}},[_c('i',{staticClass:"el-icon-error text-red-500 text-2xl"})]):_vm._e()])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }